import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BannerSlogan } from '../components/BannerSlogan';
import { ServicesWrapper } from '../components/ServicesWrapper';
import { WaaromOns } from '../components/WaaromOns';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Home</title>
                </Helmet>
                <BannerSlogan text={["Wij bouwen niet zomaar websites.", <br key="br" />, "Wij maken voordelig uw professionele website op maat."]} />
                <ServicesWrapper />
                <WaaromOns />
            </>
        );
    }
}
