import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ContactForm } from '../components/ContactForm';

export class Contact extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Contact</title>
                    <meta name="description" content="Contact met Web-net. Even sparren over een nieuwe website? Stuur een bericht of bel. Web-net." data-react-helmet="true" />
                    <meta property="og:title" content="Contact met Web-net" data-react-helmet="true" />
                    <meta property="og:description" content="Contact met Web-net. Even sparren over een nieuwe website? Stuur een bericht of bel. Web-net." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/contact" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax" style={{ backgroundImage: "url(/img/banner_contact.jpg)" }}></figure>
                <Container>
                    <div className="inner" data-test={this.props.action}>
                        <h1>Contact</h1>
                        <Row>
                            <Col xs="12" md="8">
                                <article>
                                    <h2>Contact opnemen is simpel (en gratis!)</h2>
                                    <p>Vul hieronder het formulier in, of stuur een mail naar <Link to="stuurmail">info@web-net.nl</Link>.</p>
                                    { <ContactForm /> }
                                </article>
                            </Col>
                            <Col xs="12" md="4">
                                <article>
                                    <h2>Andere contactmogelijkheden</h2>
                                    <p><Link to="stuurmail">info@web-net.nl</Link><br /><Link to="bel">06 27 48 23 60</Link>.</p>
                                </article>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        );
    }
}