import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import './NavMenu.scss';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white" light>
                    <Container>
                            <NavbarBrand tag={Link} to={'/'}>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <image xlinkHref="/img/logo_web-net.svg" />
                                </svg>
                            </NavbarBrand>
                            <div className="d-sm-inline-flex collapse navbar-collapse">
                                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse className="d-flex justify-content-end" isOpen={!this.state.collapsed} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        <NavItem>
                                            <NavLink className="text-dark" to="/" exact>Home</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="text-dark" to="/website-laten-maken">Website</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="text-dark" to="/webshop-laten-maken">Webshop</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="text-dark" to="/onderhoud">Onderhoud</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="text-dark" to="/tarieven">Tarieven</NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                    <NavLink to="/winactie">Winactie</NavLink>
                                </NavItem>
                                */}
                                        <NavItem>
                                            <NavLink className="text-dark" to="/contact">Contact</NavLink>
                                        </NavItem>
                                    </ul>
                                </Collapse>
                            </div>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
