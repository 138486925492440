import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BannerSlogan } from '../components/BannerSlogan';
import { TarievenBlok } from '../components/TarievenBlok';

export class Tarieven extends Component {
    toggle(e, gesloten, open) {
        e.preventDefault();
        var containerToClose = document.getElementById('vraag' + gesloten);

        // Hide the previous slide
        containerToClose.style.height = '0px';

        // Show the next slide
        var containerToOpen = document.getElementById('vraag' + open);
        var height = '100%';
        containerToOpen.style.height = '0px';

        setTimeout(function () {
            containerToOpen.style.height = height;
        }, 0);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Tarieven</title>
                    <meta name="description" content="Wat kost een website of webshop? Alle tarieven op een rij. Duidelijk, helder en zonder verassingen. Web-net." data-react-helmet="true" />
                    <meta property="og:title" content="Wat kost een website of webshop? Web-net." data-react-helmet="true" />
                    <meta property="og:description" content="Wat kost een website of webshop? Alle tarieven op een rij. Duidelijk, helder en zonder verassingen. Web-net." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/tarieven" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax" style={{ backgroundImage: "url(/img/banner_tarieven.jpg)" }}></figure>
                <Container>
                    <div className="inner">
                        <h1>Tarieven</h1>
                        <Row>
                            <Col xs="12" md="8">
                                <p>De tarieven voor websites kunnen erg uiteen lopen. Zo zijn er ook vele verschillende soorten websites, waarbij de een groter is dan de ander. Om een idee te krijgen van de kosten kun je in het schema hieronder een richtprijs terug vinden. Mocht je niet weten naar welke je moet kijken dan kun je rechts in het kladblokje dit terug vinden.</p>
                                <p>De genoemde pakketprijzen zijn <strong>eenmalig</strong>.Er zijn verder <u>geen</u> maandelijkse kosten. De enige kosten die erbij komen zijn de kosten voor uw domeinnaam en hosting (circa 60 euro per jaar). Je bent vrij om elk willekeurige partij hiervoor te kiezen.</p>
                                <p><strong>Let op:</strong> De genoemde prijzen zijn indicatief. Je kunt altijd vrijblijvend een offerte op maat aanvragen. Neem hiervoor <NavLink to="contact">contact</NavLink> op.</p>
                            </Col>
                            <Col xs="12" md="4">
                                <div className="sticky-container">
                                    <div className="sticky-outer">
                                        <div className="sticky">
                                            <svg width="0" height="0">
                                                <defs>
                                                    <clipPath id="stickyClip" clipPathUnits="objectBoundingBox">
                                                        <path
                                                            d="M 0 0 Q 0 0.69, 0.03 0.96 0.03 0.96, 1 0.96 Q 0.96 0.69, 0.96 0 0.96 0, 0 0"
                                                            strokeLinejoin="round"
                                                            strokeLinecap="square" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <div className="sticky-content">
                                                <div className="webvorm_wrapper">
                                                    <div id="vraag0" className="vraag active">
                                                        <p className="vraag_titel">Wilt u producten verkopen?</p>
                                                        <Link to="tarieven" className="webvorm_link webvorm_link_l" onClick={ e => this.toggle(e, 0, 1)}>Ja</Link>
                                                        <Link to="tarieven" className="webvorm_link webvorm_link_r" onClick={ e => this.toggle(e, 0, 2)}>Nee</Link>
                                                    </div>
                                                    <div id="vraag1" className="vraag">
                                                        <p className="titel">Ons advies:</p>
                                                        <Link className="advies" to="/webshop-laten-maken">Webshop</Link>
                                                        <Link to="tarieven" className="btn btn-primary" onClick={e => this.toggle(e, 1, 0)}>Herstart test</Link>
                                                    </div>
                                                    <div id="vraag2" className="vraag">
                                                        <p className="vraag_titel">Wilt u zelf uw website kunnen aanpassen?</p>
                                                        <Link to="tarieven" className="webvorm_link webvorm_link_l" onClick={e => this.toggle(e, 2, 3)}>Ja</Link>
                                                        <Link to="tarieven" className="webvorm_link webvorm_link_r" onClick={e => this.toggle(e, 2, 4)}>Nee</Link>
                                                    </div>
                                                    <div id="vraag3" className="vraag">
                                                        <p className="titel">Ons advies:</p>
                                                        <Link className="advies" to={{ pathname: "/website-laten-maken", props: { highlightedId: "cms" } }}>CMS website</Link>
                                                        <Link to="tarieven" className="btn btn-primary" onClick={e => this.toggle(e, 3, 0)}>Herstart test</Link>
                                                    </div>
                                                    <div id="vraag4" className="vraag">
                                                        <p className="titel">Ons advies:</p>
                                                        <Link className="advies" to={{ pathname: "/website-laten-maken", props: { highlightedId: "gewoon" } }}>Gewoon een site</Link>
                                                        <Link to="tarieven" className="btn btn-primary" onClick={e => this.toggle(e, 4, 0)}>Herstart test</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <TarievenBlok title="Onderhoud" price="30" li1="Voor (CMS)website of webshop" li2="Updaten, versnellen, beveiligen" li3="Realisatie van uitbreidingen" li4="Per direct hulp" link="/onderhoud" />
                            <TarievenBlok title="Website" price="150" li1="Super snelle website" li2="Vrijwel geen onderhoud nodig" li3="Geen gedoe: gewoon een site" li4="Stuur wensen gewoon door" link="/website-laten-maken" highlightedId="gewoon" />
                            <TarievenBlok title="CMS website" price="300" li1="Inhoud zelf te beheren" li2="Geen technische kennis nodig" li3="Automatische backups" li4="Groot aanbod van plug-ins" link="/website-laten-maken" highlightedId="cms" />
                            <TarievenBlok title="Webshop" price="450" li1="CMS website +" li2="Zelf producten beheren" li3="Gemakkelijk in beheer" li4="Online betalingen" link="/webshop-laten-maken" />
                            <TarievenBlok title="Maatwerk" price="?" li1="Kan van alles zijn" li2="Met een (CMS) website" li3="Met webshop mogelijk" li4="Specifieke wensen gerealiseerd" link="/maatwerk" />
                        </Row>
                    </div>
                </Container>
                <BannerSlogan text={["Eens sparren? Neem vrijblijvend contact op"]} />
                <div ref={el => (this.instance = el)} />
            </>
        );


    }
}