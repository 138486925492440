import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

export class AlgemeneVoorwaarden extends Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Algemene voorwaarden</title>
                    <meta name="description" content="Algemene voorwaarden in een duidelijk overzicht. Geen gedoe bij website laten maken. Web-net." data-react-helmet="true" />
                    <meta property="og:title" content="Algemene voorwaarden van Web-net" data-react-helmet="true" />
                    <meta property="og:description" content="Algemene voorwaarden in een duidelijk overzicht. Geen gedoe bij website laten maken. Web-net." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/algemene-voorwaarden" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Algemene voorwaarden</h1>
                        <article>
                            <h2>Artikel 1. Algemeen</h2>
                            <p>1.1 Deze voorwaarden zijn van toepassing op iedere aanbieding, offerte of overeenkomst tussen u, de klant, en het bedrijf Web-net (ookwel handelsnaam Bjorn Hoogeveen ICT-diensten).</p>
                            <p>1.2 Indien zich tussen partijen een situatie voordoet die niet in deze algemene voorwaarden geregeld is, dan dient deze situatie te worden beoordeeld naar de geest van deze algemene voorwaarden.</p>
                            <p>1.3 Web-net behoudt het recht om de algemene voorwaarden zonder voorafgaande aankondiging te wijzigen.</p>
                        </article>
                        <article>
                            <h2>Artikel 2. Contracten, overeenkomsten en betalingen</h2>
                            <p>2.1 Alle contracten en overeenkomsten waarmee op papier, of digitaal akkoord is gegaan, zullen nageleefd moeten worden.</p>
                            <p>2.2 Betalingen moeten binnen de afgesproken termijn voldaan zijn. Web-net zal u op een betalingsherinnering sturen.</p>
                            <p>2.3 Het bedrag zal in geval van te laat betalen per kalender maand met 10% verhogen, tenzij wettelijk anders wordt gesteld. Na het niet betalen zal er een sommatiebrief verstuurd worden, gevolgd door een incasso. Alle bijkomende kosten van inning komen geheel op rekening van de opdrachtgever.</p>
                            <p>2.4 Bij genoemde prijzen is BTW uitgesloten in verband met een vrijstelling OB o.g.v. artikel 25 Wet OB, tenzij anders aangegeven.</p>
                            <p>2.5 Bij afname van een dienst worden eventuele extra kosten achteraf in rekening gebracht. Extra kosten kunnen gemaakt worden wanneer er extra werk verricht wordt dat niet in de prijsopgaaf meegenomen is. Voordat extra werk verricht wordt zal dit altijd gemeld worden, zodat de klant niet voor onverwachte kosten komt te staan.</p>
                            <p>2.6 Indien een opdracht vroegtijdig wordt afgebroken door de klant zal er een eindafrekening volgen voor de gemaakte werkzaamheden. Web-net behoud het recht om het niet gepubliceerde werk te gebruiken in eigen portfolio, met uitsluiting van eventuele gevoelige data.</p>
                            <p>2.7 De offerte van een opdracht mag tussentijds veranderd worden, mits hierover vooraf contact heeft plaats gevonden en opnieuw akkoord is gegaan door beide partijen. Indien niet akkoord is gegaan en er geen oplossing gevonden kan worden, wordt de offerte ontbonden. Hierbij gaat artikel 2, paragraaf 6 van kracht.</p>
                            <p>2.8 Na het akkoord gaan van de offerte of prijsopgaaf, zal de klant het bijbehorende factuur binnen 1 maand moeten voldoen. Na ontvangst van betaling zullen de werkzaamheden verricht worden.</p>
                            <p>2.9 Prijsafspraken gelden per website. Er zal altijd een prijs worden afgesproken afhankelijk van de hoeveelheid werk. Het is niet zo dat elke volgende website even duur is.</p>
                            <p>2.10 Indien de opdrachtgever failliet wordt verklaart of in financiele problemen zit, behoud Web-net het recht om de overeenkomst te ontbinden.</p>
                        </article>
                        <article>
                            <h2>Artikel 3. Privacyregeling</h2>
                            <p>3.1 Alleen persoonsgegevens die noodzakelijk zijn voor onze dienstverlening en verbeteringen worden opgeslagen.</p>
                            <p>3.2 Uw persoonsgegevens worden met zorg verwerkt, opgeslagen en beheerd.</p>
                            <p>3.3 Alle overige gegevens die eventuele medewerkers onder ogen komen, worden strikt vertrouwelijk behandeld (documenten, e-mails etc). Uitzonderingen zijn criminele zaken, waaronder kinderporno. In dit geval zal er aangifte gedaan worden.</p>
                            <p>3.4 Als consument heeft u recht op inzage van uw persoonsgegevens. Deze gegevens sturen wij u uiterlijk twee weken na uw verzoek om inzage toe. Op uw verzoek zullen wij uw gegevens aanpassen of verwijderen.</p>
                        </article>
                        <article>
                            <h2>Artikel 4. Aansprakelijkheid</h2>
                            <p>4.1 Indien Web-net aansprakelijk mocht zijn, dan is deze aansprakelijkheid beperkt tot hetgeen in deze bepaling is geregeld.</p>
                            <p>4.2 Web-netniet aansprakelijk voor schade, van welke aard ook, ontstaan doordat Web-net uitgegaan van door of namens de opdrachtgever verstrekte onjuiste en / of onvolledige gegevens.</p>
                            <p>4.3 Indien Web-net aansprakelijk mocht zijn voor schade, dan is de aansprakelijkheid van Web-net beperkt tot maximaal tweemaal de factuurwaarde van de order, althans tot dat gedeelte van de order waarop de aansprakelijkheid betrekking heeft.</p>
                            <p>4.4 De aansprakelijkheid van Web-net is in ieder geval steeds beperkt tot het bedrag der uitkering van zijn verzekeraar in voorkomend geval.</p>
                            <p>4.5 Web-net is uitsluitend aansprakelijk voor directe schade.</p>
                            <p>4.6 De in dit artikel opgenomen beperkingen van de aansprakelijkheid gelden niet indien de schade te wijten is aan opzet of grove schuld van Web-net.</p>
                        </article>
                        <article>
                            <h2>Artikel 5. Overmacht</h2>
                            <p>5.1 Er is sprake van overmacht als Web-net na de totstandkoming van de overeenkomst niet aan verplichtingen kan voldoen als gevolg van een oorzaak of situatie die buiten de schuld of risicosfeer van Web-net ligt.Je kan dan denken aan ziekte, maar ook zaken zoals brand, waterschade, natuurgeweld, technische storingen, overheidsmaatregelen, een pandemie of epidemie. De opsomming uit dit artikel zijn voorbeelden om je een beeld te geven, maar er kunnen ook andere situaties zijn die hier niet zijn genoemd maar die wel onder overmacht vallen.</p>
                            <p>5.2 Helaas kan het voorkomen dat Web-net zijn/haar verplichtingen uit de overeenkomst niet kan nakomen als gevolg van overmacht. Als dat het geval is, zal Web-net dit zo snel mogelijk kenbaar maken. De nakoming van de overeenkomst door Web-net wordt in dat geval net zo lang opgeschort als de overmacht duurt.</p>
                            <p>5.3 Het kan gebeuren dat een dienst niet volledig uitgevoerd is voor de afgesproken datum wegens overmacht. In dit geval zal er een nieuwe afspraak komen, voor een nieuwe datum.</p>
                            <p>5.4 Indien overmacht langer dan twee maanden voortduurt, zullen indien gewenst door de opdrachtgever overeenkomsten worden ontbonden, zonder verdere verplichtingen en betalingen.</p>
                        </article>
                        <article>
                            <h2>Artikel 6. Intellectueel eigendom</h2>
                            <p>6.1 Alle door Web-net gemaakte bestanden zijn eigendom van het bedrijf. U zult hiervan na ontvangst van de laatste betaling eigenaar van zijn. De bestanden bij het maken van een website heeft u ook toegang tot. Deze bestanden claimen voor ontvangst van betaling is echter strafbaar, en zal vallen onder diefstal.</p>
                            <p>6.2 Hoewel na betaling alle bestanden en data omtrent de website in uw eigendom zijn, zal de garantie vervallen op het moment dat er wijzigingen worden aangebracht door een derde partij.</p>
                        </article>
                        <article>
                            <h2>Artikel 7. Overige</h2>
                            <p>7.1 Web-net zal zo veel mogelijk doen om alle risico's te beperken. Denk hierbij aan het maken van back-ups. Dit is echter een gunst, en het is dus mogelijk dat er geen back-up beschikbaar is.</p>
                            <p>7.2 Web-net zal elke verplichting tot de opdracht nakomen, indien hij daartoe niet gehinderd wordt door overmacht.</p>
                            <p>7.3 Partijen zullen enkel een beroep op de rechter doen nadat zij zich tot het uiterste hebben ingespannen een geschil in onderling overleg te beslechten.</p>
                        </article>
                    </div>
                </Container>
            </>
        );
    }
}
