import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Col, Container, NavItem, Row } from 'reactstrap';
import './Footer.scss';

export class Footer extends Component {
    render() {
        return (
            <footer>
                <Container>
                    <div className="inner">
                        <Row>
                            <Col xs="12" md="4">
                                <h3>Meer over...</h3>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem><NavLink to="/website-laten-maken">(CMS) Website</NavLink></NavItem>
                                    <NavItem><NavLink to="/webshop-laten-maken">Webshop</NavLink></NavItem>
                                    <NavItem><NavLink to="/tarieven">Tarieven</NavLink></NavItem>
                                    <NavItem><NavLink to="/contact">Contact</NavLink></NavItem>
                                </ul>
                            </Col>
                            <Col xs="12" md="4">
                                <h3>Overige links</h3>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem><NavLink to="/algemene-voorwaarden">Algemene voorwaarden</NavLink></NavItem>
                                    <NavItem><NavLink to="/cookies">Cookies</NavLink></NavItem>
                                </ul>
                            </Col>
                            <Col xs="12" md="4">
                                <h3>Contactinformatie</h3>
                                <ul className="navbar-nav flex-grow"><NavItem><NavLink to="/contact">Naar contactpagina</NavLink></NavItem></ul>
                                <p>
                                   Mobiel: <Link to="bel">06 27 48 23 60</Link><br />
                                   Email: <Link to="stuurmail">info@web-net.nl</Link>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </footer>
        );
    }
}
