import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BannerSlogan } from '../components/BannerSlogan';
import { ImageBlock } from '../components/ImageBlock';

export class Onderhoud extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Onderhoud</title>
                    <meta name="description" content="Ook een website of webshop heeft onderhoud nodig. Laat het uitvoeren door Web-net voor een professionele APK." data-react-helmet="true" />
                    <meta property="og:title" content="Website of webshop laten onderhouden? Web-net." data-react-helmet="true" />
                    <meta property="og:description" content="Ook een website of webshop heeft onderhoud nodig. Laat het uitvoeren door Web-net voor een professionele APK." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/onderhoud" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax fade-in" style={{ backgroundImage: "url(/img/banner_onderhoud.jpg)" }}></figure>
                <Container>
                    <div className="inner">
                        <h1>Onderhoud</h1>
                        <Row>
                            <Col xs="12" md="6">
                                <div className="innerBox">
                                    <h2>Waarom onderhoud?</h2>
                                    <p>Door je website te onderhouden blijft je website snel, goed gevonden en bovenal veilig. Veel van dit werk zit onder de motorkap. Je kunt natuurlijk ook visueel onderhoud plegen. Een nieuw logo, andere kleuren, nieuwe features.</p>
                                </div>
                            </Col>
                            <Col xs="12" md="6">
                                <div className="innerBox">
                                    <h2>Wat wordt er gedaan?</h2>
                                    <p>Het onderhoud is afhankelijk van het type website. Heb je een <NavLink to={{ pathname: "/website-laten-maken", props: { highlightedId: "gewoon" } }} exact>gewone site</NavLink>, <NavLink to={{ pathname: "/website-laten-maken", props: { highlightedId: "cms" } }} exact>CMS</NavLink> of <NavLink className="advies" to="/webshop-laten-maken">webshop</NavLink>? Tijdens het technisch onderhoud zal er altijd naar beveiliging gekeken worden. Ook worden, indien van toepassing, plugins en thema's bijgewerkt. Indien gewenst kan er ook naar de snelheid en vindbaarheid gekeken worden.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <ImageBlock image="/img/service_security.jpg" title="Beveiliging" summary="Wij helpen om je website veilig te houden. Het bijwerken van plug-ins en thema's, maar ook technischere zaken zoals het inbouwen van Captcha, ophogen van PHP versies, SSL certificiaten, SPAM tegengaan, en nog veel meer." />
                            <ImageBlock image="/img/service_speed.jpg" title="Snelheid" summary="Is je al opgevallen dat deze website snel reageert? We besteden extra aandacht aan het snel inladen van je website. Dit komt de gebruikerservaring zeer ten goede." />
                            <ImageBlock image="/img/service_wish.jpg" title="Wensen" summary="Ongetwijfelt dat er met de tijd nieuwe wensen komen voor de website. We denken met je mee en zorgen dat we samen tot een nog betere oplossing komen dan vooraf gedacht." />
                        </Row>
                    </div>
                </Container>
                <BannerSlogan text={["Benieuwd? Neem vrijblijvend contact op"]} /></>
        );
    }
}