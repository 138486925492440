import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BannerSlogan } from '../components/BannerSlogan';

export class Maatwerk extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Maatwerk</title>
                    <meta name="description" content="Wanner moet er iets op maat gemaakt worden, en wanneer is dit niet nodig omdat het al bestaat? Het antwoord op deze vragen staan op Web-net." data-react-helmet="true" />
                    <meta property="og:title" content="Maatwerk professioneel en voordelig ontwikkeld door Web-net." data-react-helmet="true" />
                    <meta property="og:description" content="Wanner moet er iets op maat gemaakt worden, en wanneer is dit niet nodig omdat het al bestaat? Het antwoord op deze vragen staan op Web-net." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/maatwerk" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax fade-in" style={{ backgroundImage: "url(/img/banner_onderhoud.jpg)" }}></figure>
                <Container>
                    <div className="inner">
                        <h1>Maatwerk</h1>
                        <Row>
                            <Col xs="12">
                                <div className="innerBox">
                                    <article>
                                        <h2>Wat is maatwerk?</h2>
                                        <p>Wanner moet er iets op maat gemaakt worden, en wanneer is dit niet nodig omdat het al bestaat? Dat kan soms lastig uit te vinden zijn.</p>
                                        <p>Maatwerk is benodigd wanneer er vraag is naar een functionaliteit die niet standaard aanwezig is. In de regel kennen wij een aantal standaard functionaliteiten. Uiteraard valt hier het plaatsen van teksten en foto's onder. Daarnaast bieden sommige thema's binnen een <Link className="advies" to={{ pathname: "/website-laten-maken", props: { highlightedId: "cms" } }}>CMS</Link> de mogelijkheid om een eigen pagina indeling te maken, met kleuren te werken, tagging en nog meer zaken.</p>
                                    </article>
                                    <article>
                                        <h3>Veel voorkomend maatwerk</h3>
                                        <p>Onderdelen die vaak voorkomen als maatwerk zijn rekenmodules, koppelingen met andere systemen en een platform waarop er kan worden ingelogd en hierna berichten kunt plaatsen of interessegebonden informatie kunt vinden.</p>
                                        <p>Heb je een idee waarbij je een grote partij als Facebook, Twitter, Linkedin, Google, Booking.com, etc. als voorbeeld gebruikt? Dan heb je een grote kans dat er behoefte is aan een oplossing op maat.</p>
                                    </article>
                                </div>
                            </Col>
                        </Row>
                    </div></Container>
                <BannerSlogan text={["Direct aan de slag?", <br key="br" />, "Wil je iets op maat laten maken? Neem dan vrijblijvend contact op."]} />
            </>
        );
    }
}