import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import App from './App';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import './custom.scss';

const container = document.getElementById('root');
const root = createRoot(container);

// Google Analytics implementation
ReactGA.initialize('G-BCNLV1QB0T') // 283680203

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    setTimeout(
        function () {
            let element = null;
            if (window.innerWidth <= 575) {
                element = document.querySelector("h1");
            } else {
                element = document.querySelector("body");
            }
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 100); // A little delay so the javascript doens't get the position of the previous page

    if (location.pathname === "/bel") {
        window.location.href = "tel:+31627482360";
    } else if (location.pathname === "/stuurmail") {
        window.location.href = "mailto:info@web-net.nl";
    }
});

root.render(
    <Router basename={baseUrl} history={history}>
        <App />
    </Router>);