import React, { Component } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.scss';
import { AlgemeneVoorwaarden } from './Pages/AlgemeneVoorwaarden';
import { Contact } from './Pages/Contact';
import { Cookies } from './Pages/Cookies';
import { Home } from './Pages/Home';
import { Maatwerk } from './Pages/Maatwerk';
import { NotFound } from './Pages/NotFound';
import { Onderhoud } from './Pages/Onderhoud';
import { Tarieven } from './Pages/Tarieven';
import { WebshopLatenMaken } from './Pages/WebshopLatenMaken';
import { WebsiteLatenMaken } from './Pages/WebsiteLatenMaken';
import { Winactie } from './Pages/Winactie';

export default class App extends Component {
    componentDidMount() {
        loadReCaptcha("6Lc52A8cAAAAAHLeFhaiNijFKqUO5yVdBiqrbpud", this.callback());
    }

    callback() {
        // reCaptcha callback if needed.
    }

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/website-laten-maken' component={WebsiteLatenMaken} />
                    <Route path='/webshop-laten-maken' component={WebshopLatenMaken} />
                    <Route path='/onderhoud' component={Onderhoud} />
                    <Route path='/tarieven' component={Tarieven} />
                    <Route path='/winactie' component={Winactie} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/maatwerk' component={Maatwerk} />
                    <Route path='/algemene-voorwaarden' component={AlgemeneVoorwaarden} />
                    <Route path='/cookies' component={Cookies} />
                    <Route path='/stuurmail' render={(props) =>
                        <Contact action="stuurmail" />
                    } />
                    <Route path='/bel' render={(props) =>
                        <Contact action="bel" />
                    } />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}