import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './BannerSlogan.scss';

export class BannerSlogan extends Component {
    render() {
        return (
            <section id="bannerslogan" className="fade-in">
                <Container>
                    <div className="inner">
                        <Row>
                            <Col xs="12" md="9">
                                <h3>{this.props.text}</h3>
                            </Col>
                            <Col xs="12" md="3">
                                <NavLink to="/contact">Contact</NavLink>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }
}
