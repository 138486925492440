import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Collapse, Container } from 'reactstrap';

export class Winactie extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        var accordionItem1 = { id: 1, title: "Wat win je precies?", summary: "<p>We zetten een WordPress installatie op met een afgestemd thema. De website zal gevuld worden met voorbeeld content. De echte teksten en afbeeldingen voeg je zelf in.</p>" }
        var accordionItem2 = { id: 2, title: "Wat zit er niet bij?", summary: "<p>Een website vereist een domeinnaam en hosting. Deze twee aspecten zitten hier niet bij, en dienen zelf aangeschaft te worden. Deze services verzorgen wij niet zelf. Er zijn vele leveranciers hiervoor. <p>In WordPress kun je kiezen voor een thema. Dit bepaald de lay-out van de website. Bij de actie is er uitgegaan van een gratis thema. Een betaald thema is mogelijk, maar vereist dat deze zelf wordt aangeschaft.</p>" }
        var accordionItem3 = { id: 3, title: "Hoe zit het met onderhoud?", summary: "<p>Een website is net als een auto. Het heeft zo nu en dan onderhoud nodig. Dit betreft het updaten van WordPress zelf, de onderliggende plugins en thema’s. Daarnaast is het zaak om het systeem veilig en snel te houden. De wereld van het web veranderd snel.</p><p>Onderhoud is uitgesloten van deze win actie. Er dient minimaal twee maal per jaar onderhoud te laten plaats vinden. Gemiddeld kost dit rond de 60 euro per onderhoudsbeurt.</p>" }
        var accordionItem4 = { id: 4, title: "Hoe zit het met support?", summary: "<p>Initieel wordt de website gevuld met voorbeeld teksten en afbeeldingen. De echte vulling kun je zelf doen in het CMS. Uiteraard valt onder de winactie ook contact over de verdere details.</p><p>Moeite om teksten en afbeeldingen in te voegen? Dan help ik graag mee. Dit kan in vorm van een training of een vragen-antwoorden uurtje. Hier ontvang je achteraf een factuur voor.</p><p>Liever het vullen uitbesteden? Dat kan natuurlijk ook!</p>" }
        var accordionItem5 = { id: 5, title: "Wat zijn de actievoorwaarden?", summary: "<dl><li>Deelname aan de winactie is gratis.</li><li>Deelname kan enkel door het formulier op deze pagina in te vullen.</li><li>Na de looptijd van de actie zal er een winnaar getrokken worden uit de inzendingen. Dit zal een willekeurige winnaar zijn.</li><li>Met het insturen van het formulier gaat u akkoord dat u per mail benaderd kunt worden voor marketing doeleinden van Web-net.</li><li>Iedere deelnemer kan maximaal één keer deelnemen aan de winactie.</li><li>Om deel te mogen nemen, moet een deelnemer een minimumleeftijd hebben van 16 jaar en moet de deelnemer woonachtig zijn in Nederland. Alle andere deelnemers zijn uitgesloten van deelname.</li><li>De trekking van de winnaars geschiedt willekeurig en op onpartijdige wijze.</li><li>De winnaar wordt geïnformeerd via of het e-mailadres waarmee aan de actie is deelgenomen.</li><li>Over uitslagen wordt niet gecorrespondeerd.</li><li>De prijs is niet inwisselbaar voor geld of andere goederen.</li><li>De winnaar dient uiterlijk binnen 2 weken na het bekend maken te reageren, anders zal er een nieuwe winnaar gekozen worden.</li><li>De persoonsgegevens die in het kader van de winactie worden verkregen, worden niet verstrekt aan derden.</li><li>In de gevallen waarin deze voorwaarden niet voorzien zal een besluit worden genomen door Web-net.</li><li>Deelnemers die niet voldoen aan bovengenoemde voorwaarden kunnen worden uitgesloten van deelname.</li><li>Web-net is te allen tijde bevoegd actievoorwaarden tussentijds te wijzigen of de acties zonder opgaaf van reden stop te zetten.</li><li>Support en onderhoud van de website is uitgesloten van deze win actie. Zo ook kosten voor domeinnaam en hosting.</li></dl>" }

        this.state = { collapse: 0, cards: [accordionItem1, accordionItem2, accordionItem3, accordionItem4, accordionItem5] };
    }

    toggle(e) {
        let event = e.target.dataset.event;
        this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
    }

    render() {
        const { cards, collapse } = this.state;
        return (
            <>
                <Helmet>
                    <title>Web-net | Winactie</title>
                    <meta name="description" content="Winactie! Win een gratis Wordpress website. Vul het formulier in en win!" data-react-helmet="true" />
                    <meta property="og:title" content="Winactie! Win een gratis Wordpress website. Vul het formulier in en win!" data-react-helmet="true" />
                    <meta property="og:description" content="Winactie! Win een gratis Wordpress website. Vul het formulier in en win!" data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/winactie" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax fade-in" style={{ backgroundImage: "url(/img/banner_winactie.jpg)" }}></figure>
                <Container>
                    <div className="inner">
                        <article>
                            <h1>Winactie</h1>
                            <p><strong>Helaas, deze actie is verlopen!</strong></p>
                            <p>Wil je ook gratis een WordPress website? Dat kan!<br /><br />Je hoeft alleen het formulier in te vullen om mee te doen aan de actie.<br /><br />Ideaal als je een eigen bedrijf wilt beginnen, je (bedrijfs)website simpelweg aan vervangen toe is of je bijvoorbeeld een eigen blog wilt starten.</p>
                        </article>

                        <article>
                            <h2>Wat kun je winnen?</h2>
                            <p>Je wint een gratis WordPress website. WordPress is een Content Management Systeem (CMS). Lees hier meer over wat een CMS is.<br /><br />Zoals je bij de <NavLink to="/tarieven">tarieven</NavLink> kunt lezen wordt deze normaal verkocht voor 300 euro. Win je deze actie, dan krijg je deze implementatie gratis!<br /><br /><strong>Let op</strong>: deze actie loopt tot <strong>31-08-2020</strong>.</p>

                            <div className="container">
                                {cards.map(index => {
                                    return (
                                        <Card style={{ marginBottom: '1rem' }} key={index.id}>
                                            <CardHeader onClick={this.toggle} data-event={index.id}>{index.title}</CardHeader>
                                            <Collapse isOpen={collapse === index.id}>
                                                <CardBody dangerouslySetInnerHTML={{ __html: index.summary }}></CardBody>
                                            </Collapse>
                                        </Card>
                                    )
                                })}
                            </div>
                        </article>
                    </div>
                </Container></>
        );
    }
}