import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

export class Cookies extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Cookies</title>
                    <meta name="description" content="Cookiebeleid van Web-net. Alle lekkere koekjes op een rij." data-react-helmet="true" />
                    <meta property="og:title" content="Cookiebeleid van Web-net. Alle lekkere koekjes op een rij." data-react-helmet="true" />
                    <meta property="og:description" content="Overzichtelijke pagina met alle cookies op een rij. Web-net maakt het duidelijk." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/cookies" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Cookies</h1>
                        <p>Deze website maakt gebruik van cookies. Dit betreft cookies voor reCaptcha en Google Analytics. De cookies zijn zo ingesteld waardoor we voldoen aan de wetgeving, en geen actieve cookiemelding verplicht zijn. Uiteraard leggen we wel graag uit welke cookies we gebruiken, en waarvoor.</p>
                        <table className="table table-dark table-hover table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Bron</th>
                                    <th scope="col">Cookie naam</th>
                                    <th scope="col">Duur</th>
                                    <th scope="col">Doel</th>
                                    <th scope="col">Meer info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>web-net.nl</td>
                                    <td>_gid</td>
                                    <td>1 dag</td>
                                    <td>Gebruikt om gebruikers te onderscheiden</td>
                                    <td><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=nl" target="_blank" rel="noopener noreferrer">Meer info</a></td>
                                </tr>
                                <tr>
                                    <td>web-net.nl</td>
                                    <td>_ga</td>
                                    <td>2 jaar</td>
                                    <td>Gebruikt om gebruikers te onderscheiden</td>
                                    <td><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=nl" target="_blank" rel="noopener noreferrer">Meer info</a></td>
                                </tr>
                                <tr>
                                    <td>web-net.nl</td>
                                    <td>_ga_XXXXXXXX</td>
                                    <td>2 jaar</td>
                                    <td>Wordt gebruikt om de sessiestatus te behouden</td>
                                    <td><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=nl" target="_blank" rel="noopener noreferrer">Meer info</a></td>
                                </tr>
                                <tr>
                                    <td>recaptcha.net</td>
                                    <td>_GRECAPTCHA</td>
                                    <td>180 dagen</td>
                                    <td>Wordt gebruikt om spambeveiliging te bieden</td>
                                    <td><a href="https://cookiedatabase.org/cookie/google-recaptcha/_grecaptcha/" target="_blank" rel="noopener noreferrer">Meer info</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Container>
            </>
        );
    }
}