import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { BannerSlogan } from '../components/BannerSlogan';
import { Helmet } from 'react-helmet';

export class NotFound extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | 404</title>
                    <meta name="description" content="Soms kun je even iets niet vinden... Kijk eens op een andere pagina van Web-net." data-react-helmet="true" />
                    <meta property="og:title" content="Soms kun je even iets niet vinden... 404. Web-net." data-react-helmet="true" />
                    <meta property="og:description" content="Soms kun je even iets niet vinden... Kijk eens op een andere pagina van Web-net." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/404" data-react-helmet="true" />
                </Helmet>
                <Container>
                    <div className="inner">
                        <h1>Pagina niet gevonden - 404</h1>
                        <p>Helaas, deze pagina is niet gevonden. Misschien helpen de onderstaande linkjes.</p>
                        <dl>
                            <li><NavLink activeClassName="active" className="text-dark" to="/" exact>Home</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/website-laten-maken">Website</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/webshop-laten-maken">Webshop</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/maatwerk">Maatwerk</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/onderhoud">Onderhoud</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/tarieven">Tarieven</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/winactie">Winactie</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/contact">Contact</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/bel">Bel mij</NavLink></li>
                            <li><NavLink activeClassName="active" className="text-dark" to="/stuurmail">Mail mij</NavLink></li>
                        </dl>
                    </div>
                </Container>
                <BannerSlogan text={["Of liever even iets bespreken?", <br key="br" />, "Neem vrijblijvend contact op"]} />
            </>
        );
    }
}