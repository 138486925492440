import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class TarievenBlok extends Component {
    render() {
        return (
            <div className="grid-1-5">
                <div className="header">
                    <h2>{this.props.title}</h2>
                </div>
                <small>Al vanaf </small>
                <h3>€ {this.props.price}</h3>
                <ul>
                    <li>{this.props.li1}</li>
                    <li>{this.props.li2}</li>
                    <li>{this.props.li3}</li>
                    <li>{this.props.li4}</li>
                </ul>
                <NavLink className="btn btn-primary" to={{ pathname: this.props.link, props: { highlightedId: this.props.highlightedId } }} exact>Meer informatie</NavLink>
            </div>
        );


    }
}