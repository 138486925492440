import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { ImageBlock } from './ImageBlock';
import './ServicesWrapper.scss';

// All services
var services = [
    { title: "Website", image: "/img/service_websites.jpg", summary: "Wij ontwikkelen websites van A tot en met Z. We denken met je mee, maken de website en helpen met het onderhouden." },
    { title: "CMS", image: "/img/service_cms.jpg", summary: "Wil je graag zelf de website kunnen aanpassen? Kies dan voor een Content Management Systeem." },
    { title: "Webshop", image: "/img/service_webshop.jpg", summary: "Met een webshop kun je een eigen webwinkel beginnen. Verkoop je eigen producten of diensten via een geautomatiseerd betaalproces." },
    { title: "Onderhoud", image: "/img/service_onderhoud.jpg", summary: "Jouw website is onze zorg. We zorgen ervoor dat je altijd up-to-date bent, en uw nieuwe wensen worden uitgevoerd." },
    { title: "SEO", image: "/img/service_seo.jpg", summary: "Search Engine Optimalisation, ofwel zoekmachine optimalisatie helpt om goed gevonden te worden in zoekmachines zoals Google." }
];

// Randomize them
services.sort(() => 0.5 - Math.random());

// Create ServiceWrapper object to use in the render function
// Maximum of 3 elements
const ServiceWrapper = ({ services }) => (
    <>
        {services.slice(0, 3).map(service => (
            <ImageBlock key={ service.title } alt={service.title} image={service.image} title={service.title} summary={service.summary} />
        ))}
    </>
);

export class ServicesWrapper extends Component {
    render() {
        return (
            <Container id="services">
                <div className="inner">
                    <h1>Onze service</h1>
                    <Row>
                        <ServiceWrapper services={services} />
                    </Row>
                </div>
            </Container>
        );
    }
}