import React, { Component } from 'react';
import { Container , Row, Col} from 'reactstrap';
import './WaaromOns.scss';

export class WaaromOns extends Component {
    constructor(props) {
        super(props);
        this.state = { whys: [], loading: true };
    }

    static renderWhys() {
        var why1 = { id: 1, title: "Concurrerende prijs", summary: "Omdat dit bedrijf een hobbymatig karakter heeft, hebben wij lage prijzen én een geweldig resultaat.", icon: "fa fa-laugh-beam" };
        var why2 = { id: 2, title: "Certificering", summary: "Door periodieke bijscholing ben je verzekerd van kwaliteit.", icon: "fa fa-user-graduate" };
        var why3 = { id: 3, title: "Ervaring sinds 2010", summary: "Met onze jarenlange ervaring krijg je de voorsprong op concurrentie die je verdient.", icon: "fa fa-vial" };
        var why4 = { id: 4, title: "Eén aanspreekpunt", summary: "Één vast aanspreekpunt. Direct contact zonder tussenpersonen.", icon: "fa fa-user" };
        var why5 = { id: 5, title: "Van simpel...", summary: "Gewoon een voordelige website die wel degelijk professioneel oogt? Er zijn je al vele voorgegaan!", icon: "fa fa-thumbs-up" };
        var why6 = { id: 6, title: "...tot geavanceerd", summary: "We zijn in staat om een maatwerktraject te starten en hiermee specifieke wensen te vervullen.", icon: "fa fa-cogs" };
        var whys = [why1, why2, why3, why4, why5, why6];

        return (
        <Row>
            {whys.map(why =>
                <Col className="iconBox" xs="12" md="6" lg="4" key={why.id}>
                    <figure>
                        <i className={why.icon} />
                    </figure>
                    <div className="iconContent">
                        <h3>{why.title}</h3>
                        <p>{why.summary}</p>
                    </div>
                </Col>
            )}
        </Row>
        );
    }

    render() {
        let contents = WaaromOns.renderWhys();

        return (
            <section id="waaromons" className="fade-in">
                <Container>
                    <div className="inner">
                        <h2>Waarom ons?</h2>
                        {contents}
                    </div>
                </Container>
            </section>
        );
    }


}
