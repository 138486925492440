import axios from 'axios';
import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import validator from 'validator';
import './ContactForm.scss';

let emailError;

export class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            recaptcha: '',
        }
    }

    validateEmail(email) {
        if (validator.isEmail(email)) {
            this.setEmailError('') // Empty; do not show anything if valid.
        } else {
            var newEmail = email.replace(/[<>&\\",%#!$():;[]`| ]/gmi, "");
            this.setState({
                email: newEmail
            });
            this.setEmailError('Vul een geldig e-mailadres in.')
        }
    }

    validateName(name) {
        if (!name.match(/^([a-zA-Z ]+)$/)) {
            var newName = name.replace(/[^a-zA-Z ]/gmi, "");
            this.setState({
                name: newName
            });
        }
    }

    validateMessage(message) {
        if (message.match(/([<>\\%#$[\]{}`|]+)/, "")) {
            var newMessage = message.replace(/([<>\\%#$[\]{}`|]+)/gmi, "");
            this.setState({
                message: newMessage
            });
        }
    }

    setEmailError(message) {
        emailError = message;
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({
            recaptcha: recaptchaToken
        });
    }

    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute();
    }

    render() {
        return (
            <><ReCaptcha
                ref={ref => this.recaptcha = ref}
                sitekey="6Lc52A8cAAAAAHLeFhaiNijFKqUO5yVdBiqrbpud"
                action='action_name'
                verifyCallback={this.verifyCallback}
            />
                <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input type="text" minLength="2" maxLength="164" required className="form-control" id="name" name="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <input type="email" minLength="2" maxLength="164" required className="form-control" id="email" name="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                        <span style={{ color: 'red', }}>{emailError}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Bericht</label>
                        <textarea required className="form-control" rows="5" id="message" name="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                    </div>
                    <button type="submit" className="btn btn-primary">Verstuur</button>
                </form>
            </>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
        this.validateName(event.target.value)
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
        this.validateEmail(event.target.value)
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
        this.validateMessage(event.target.value)
    }

    handleSubmit(e) {
        e.preventDefault();

        this.validateName(this.state.name)
        this.validateEmail(this.state.email)
        this.validateMessage(this.state.message)

        axios({
            method: "POST",
            url: "/static/php/postform.php",
            data: this.state
        }).then((response) => {
            if (response.data.status === 'success') {
                alert("Dankjewel voor je berichtje! We nemen snel contact op.");
                this.resetForm()
            } else if (response.data.status === 'fail') {
                alert("Excuus, er is iets mis gegaan.\nNeem contact op via info@web-net.nl.\n\nFout: " + response.data.response)
            }
        }).catch(err => {
            alert("Excuus, er is iets mis gegaan.\nNeem contact op via info@web-net.nl.\n\nFoutmelding: " + err);
        })
    }

    resetForm() {
        this.setState({ name: "", email: "", message: "" })
        this.recaptcha.execute()
    }
}
