import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BannerSlogan } from '../components/BannerSlogan';

export class WebshopLatenMaken extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Web-net | Webshop laten maken</title>
                    <meta name="description" content="Webshop (ofwel webwinkel) laten maken? Web-net maakt het profesioneel en voordelig." data-react-helmet="true" />
                    <meta property="og:title" content="Webshop (webwinkel) laten maken? Web-net maakt het profesioneel en voordelig." data-react-helmet="true" />
                    <meta property="og:description" content="Webshop (ofwel webwinkel) laten maken? Web-net maakt het profesioneel en voordelig." data-react-helmet="true" />
                    <meta property="og:url" content="https://web-net.nl/webshop" data-react-helmet="true" />
                </Helmet>
                <figure className="parallax fade-in" style={{ backgroundImage: "url(/img/banner_webshop.jpg)" }}></figure>
                <Container>
                    <div className="inner">
                        <h1>Webshop laten maken</h1>
                        <Row>
                            <Col xs="12" md="6">
                                <h2>Wat is een webshop?</h2>
                                <p>Met een webshop kun je een eigen webwinkel beginnen. In de webwinkel verkoop je jouw producten en/of diensten. Het bijhouden van een klantenbestand, het maken van facturen en de betaling zelf gebeurt allemaal door het systeem.</p>
                                <p>Je kunt allerlei soorten producten aanbieden. Per product kun je meerdere foto´s plaatsen, gebruik maken van attributen (kleur, maat, etc), en kunt u verschillende verzendkosten rekenen per product of per regio. Daarnaast zijn er nog verschillende BTW-instellingen en zijn er nog veel meer mogelijkheden binnen het systeem</p>
                            </Col>
                            <Col xs="12" md="6">
                                <h2>Voordelen van een webwinkel</h2>
                                <p>Ten eerste is het een voordelige manier om een eigen bedrijf te starten. Zo voorkom je ook de noodzaak voor een pand, wat veel kosten scheelt. Natuurlijk kun je dit ook op een andere manier bekijken voor als je al wel een pand hebt. Je creëert een extra verkoopkanaal.</p>
                                <p>Verder is een webshop 24 uur per dag en 7 dagen per week open, zonder dat je in deze tijd met vaste kosten zit. Daarnaast kun je een klantenbestand bijhouden, waarnaar je nieuwsbrieven kunt versturen om aankopen te stimuleren.</p>
                                <p>Zo zijn erg nog veel meer redenen om een webwinkel te beginnen.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <BannerSlogan text={["Direct aan de slag? Neem vrijblijvend contact op."]} />
                <Container>
                    <div className="inner">
                        <Row>
                            <Col xs="12">
                                <h2>Voor wie?</h2>
                                <p>Een webwinkel is ideaal voor een beginnende ondernemer. Daarnaast kan het zeker ook helpen als u al een bestaande ondernemer bent zonder webshop. Er zijn vele voordelen te bedenken om een online winkel te beginnen.</p>
                                <p>De kosten voor een webwinkel zal altijd vooraf besproken worden. Hierbij komen je wensen uitgebreid aan bod komen. Om een indicatie te krijgen van de kosten bekijk de <Link to="/tarieven">pagina met tarieven</Link>.</p>
                            </Col>
                        </Row>
                    </div>
                </Container></>
        );
    }
}