import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BannerSlogan } from '../components/BannerSlogan';
import { Helmet } from 'react-helmet';

export class WebsiteLatenMaken extends Component {
  constructor(props) {
      super(props);

      if (props.location.props != null) {
          this.highlightedId = props.location.props.highlightedId;
      }
  }

  render() {
      return (
          <>
              <Helmet>
                  <title>Web-net | Website laten maken</title>
                  <meta name="description" content="Website laten maken? Dit kan met of zonder CMS zoals Wordpress?. Lees er alles over op Web-net. Wij maken het profesioneel en voordelig." data-react-helmet="true" />
                  <meta property="og:title" content="Website laten maken? Web-net maakt het profesioneel en voordelig." data-react-helmet="true" />
                  <meta property="og:description" content="Website laten maken? Dit kan met of zonder CMS zoals Wordpress?. Lees er alles over op Web-net. Wij maken het profesioneel en voordelig." data-react-helmet="true" />
                  <meta property="og:url" content="https://web-net.nl/website" data-react-helmet="true" />
              </Helmet>
              <figure className="parallax fade-in" style={{ backgroundImage: "url(/img/banner_website.jpg)" }}></figure>
              <Container>
                  <div className="inner">
                      <h1>Website laten maken</h1>
                      <Row>
                          <Col xs="12" md="6" className={this.highlightedId === "gewoon" ? "highlight" : null}>
                              <div className="innerBox">
                                  <h2>Gewoon een site</h2>
                                  <p>Soms wil je gewoon een site (net zoals de site die je nu bekijkt). Geen moeilijk gedoe. Je hebt een visitekaartje nodig voor je bedrijf bijvoorbeeld. Ongetwijfeld heb je al eerder van de term CMS gehoord. Vol met mogelijkheden en opties. Maar, soms wil je gewoon iets simpels. Ook dat kan. Een CMS is namelijk helemaal niet noodzakelijk.</p>
                              </div>
                          </Col>
                          <Col xs="12" md="6" className={this.highlightedId === "cms" ? "highlight" : null}>
                              <div className="innerBox">
                                  <h2>CMS</h2>
                                  <p>Wil je graag zelf de website kunnen aanpassen? Kies dan voor een Content Management Systeem. Via het CMS kun je van alles bewerken, zoals teksten, foto’s, menu’s, knoppen, linkjes, en nog veel meer. Hiervoor is geen technische kennis nodig.</p>
                                  <p>We hebben ervoor gekozen om veelal gebruik te maken van Wordpress. Heb je een andere voorkeur? Dat is zeker ook mogelijk.</p>
                              </div>
                          </Col>
                      </Row>
                  </div>
              </Container>
              <BannerSlogan text={["Even sparren?", <br key="br"/>, "Neem vrijblijvend contact op"]} /></>
    );
  }
}