import React, { Component } from 'react';
import { Col } from 'reactstrap';

export class ImageBlock extends Component {
    render() {
        return (
            <Col className="fade-in" xs="12" md="4" lg="4">
                <figure>
                    <img src={this.props.image} alt={this.props.alt} />
                </figure>
                <h3>{this.props.title}</h3>
                <p>{this.props.summary}</p>
            </Col>
        );
    }
}